import React from "react";
import PropTypes from "prop-types";
import "./InfoNotice.scss";

const InfoNotice = ({ icon, title, children }) => {
	return (
		<div className={"info-notice"}>
			{(title || icon) && (
				<div className="info-notice__header">
					{icon && <div className="info-notice__icon">{icon}</div>}
					{title && <div className="info-notice__title">{title}</div>}
				</div>
			)}
			{children}
		</div>
	);
};

InfoNotice.propTypes = {
	icon: PropTypes.any,
	title: PropTypes.any,
	children: PropTypes.any,
};

export default InfoNotice;
