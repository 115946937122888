import * as React from "react";

function IconOccupancies(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 50.505" {...props}>
			<path
				fill="currentColor"
				d="M712,804.444H707.5a3.4,3.4,0,0,0-1.146.2l-4.855,1.739a1.949,1.949,0,0,0-1.246,1.954l.69,13.016a1.8,1.8,0,0,0,1.753,1.754h1.375l1.293,19.033a1.442,1.442,0,0,0,1.4,1.38h5.966a1.44,1.44,0,0,0,1.4-1.38l1.293-19.033H716.8a1.8,1.8,0,0,0,1.752-1.754l.691-13.016A1.948,1.948,0,0,0,718,806.383l-4.855-1.739A3.373,3.373,0,0,0,712,804.444Zm-2.245-11.429a4.788,4.788,0,1,1-4.788,4.788A4.787,4.787,0,0,1,709.752,793.015ZM735,804.444h-4.492a3.4,3.4,0,0,0-1.147.2l-4.854,1.739a1.951,1.951,0,0,0-1.247,1.954l.691,13.016a1.8,1.8,0,0,0,1.752,1.754h1.376l1.292,19.033a1.444,1.444,0,0,0,1.4,1.38h5.966a1.438,1.438,0,0,0,1.4-1.38l1.292-19.033H739.8a1.8,1.8,0,0,0,1.752-1.754l.691-13.016A1.949,1.949,0,0,0,741,806.383l-4.854-1.739A3.4,3.4,0,0,0,735,804.444Zm-2.247-11.429a4.788,4.788,0,1,1-4.788,4.788A4.787,4.787,0,0,1,732.753,793.015Z"
				data-name="Groupe 4408"
				transform="translate(-700.252 -793.015)"
			/>
		</svg>
	);
}

export default IconOccupancies;
