import * as React from "react";

function IconLocation(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.101 50" {...props}>
			<path
				fill="currentColor"
				d="M-138.836,234.067h-.147a17.627,17.627,0,0,0-17.6,17.6,17.275,17.275,0,0,0,2.6,9.2l14,22.5a1.525,1.525,0,0,0,1.2.7,1.386,1.386,0,0,0,1.2-.7l13.6-22.7a17.664,17.664,0,0,0,2.5-9A17.476,17.476,0,0,0-138.836,234.067Zm5.753,17.6a5.976,5.976,0,0,1-5.9,5.9,5.9,5.9,0,0,1-5.9-5.9,5.9,5.9,0,0,1,5.9-5.9,5.847,5.847,0,0,1,5.9,5.8C-133.082,251.6-133.082,251.631-133.083,251.666Z"
				data-name="Trac\xE9 1821"
				transform="translate(156.583 -234.066)"
			/>
		</svg>
	);
}

export default IconLocation;
