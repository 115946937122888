import * as React from "react";

function IconShare(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.994 17.225" {...props}>
			<g fill="currentColor">
				<path d="M19.848 5.096l-5.832-5a.416.416 0 00-.687.316v2.922c-4.654.129-6.9 2.433-7.967 4.375a10.114 10.114 0 00-1.183 4.045q-.013.157-.013.321a.417.417 0 00.83-.052v-.152c.182-3.464 6.581-4.25 8.327-4.4v2.885a.416.416 0 00.687.316l5.832-5a.416.416 0 000-.632z" />
				<path d="M17.252 9.373a.415.415 0 00-.445.062l-.833.714a.417.417 0 00-.145.316v5.093H1.666V5.003h4.655a.415.415 0 00.274-.1 8.948 8.948 0 011.064-.8.416.416 0 00-.224-.768H1.666A1.668 1.668 0 000 5.003v10.561a1.668 1.668 0 001.666 1.666h14.163a1.668 1.668 0 001.666-1.666V9.757a.416.416 0 00-.243-.384z" />
			</g>
		</svg>
	);
}

export default IconShare;
