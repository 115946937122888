import * as React from "react";

function IconLightning(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.89 14.059" {...props}>
			<path
				fill="currentColor"
				d="M7.89 0H4.038L.507 7.176h2.286L0 14.059 7.4 4.79l-2.792-.009z"
			/>
		</svg>
	);
}

export default IconLightning;
