import * as React from "react";

function SvgIconBookingAsideLoader(props) {
	const primaryColor = "#f0f0f0";
	const primaryOpacity = 1;
	const secondaryColor = "#e0e0e0";
	const secondaryOpacity = 1;
	const speed = 2;
	const gradientRatio = 2;
	const interval = 0.25;
	const keyTimes = `0; ${interval}; 1`;
	const dur = `${speed}s`;

	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 398 727" {...props}>
			<defs>
				<linearGradient id="linear-gradient">
					<stop offset="0%" stopColor={primaryColor} stopOpacity={primaryOpacity}>
						<animate
							attributeName="offset"
							values={`${-gradientRatio}; ${-gradientRatio}; 1`}
							keyTimes={keyTimes}
							dur={dur}
							repeatCount="indefinite"
						/>
					</stop>

					<stop offset="50%" stopColor={secondaryColor} stopOpacity={secondaryOpacity}>
						<animate
							attributeName="offset"
							values={`${-gradientRatio / 2}; ${-gradientRatio / 2}; ${1 +
								gradientRatio / 2}`}
							keyTimes={keyTimes}
							dur={dur}
							repeatCount="indefinite"
						/>
					</stop>

					<stop offset="100%" stopColor={primaryColor} stopOpacity={primaryOpacity}>
						<animate
							attributeName="offset"
							values={`0; 0; ${1 + gradientRatio}`}
							keyTimes={keyTimes}
							dur={dur}
							repeatCount="indefinite"
						/>
					</stop>
				</linearGradient>
				<linearGradient
					id="linear-gradient-3"
					x1=".5"
					x2=".5"
					y2="1"
					gradientUnits="objectBoundingBox"
				>
					<stop offset="0" stopColor="#fff" stopOpacity="0" />
					<stop offset="1" stopColor="#fff" stopOpacity=".702" />
				</linearGradient>
				<filter
					id="Rectangle_1244"
					width="358"
					height="65"
					x="11"
					y="636"
					filterUnits="userSpaceOnUse"
				>
					<feOffset dy="3" />
					<feGaussianBlur result="blur" stdDeviation="3" />
					<feFlood floodOpacity=".161" />
					<feComposite in2="blur" operator="in" />
					<feComposite in="SourceGraphic" />
				</filter>
				<filter
					id="Rectangle_1244"
					width={358}
					height={65}
					x={20}
					y={642}
					filterUnits="userSpaceOnUse"
				>
					<feOffset dy={3} />
					<feGaussianBlur result="blur-2" stdDeviation={3} />
					<feFlood floodOpacity={0.161} />
					<feComposite in2="blur-2" operator="in" />
					<feComposite in="SourceGraphic" />
				</filter>
				<style>
					{
						".cls-2,.cls-5{fill:none;stroke:#d9d9d9}.cls-2{stroke-width:2px}.cls-3{fill:#d9d9d9}.cls-4{fill:url(#linear-gradient)}"
					}
				</style>
			</defs>
			<g id="Groupe_4827" transform="translate(-815 -214)">
				<g id="Groupe_4633" transform="translate(-102)">
					<g id="Card" transform="translate(917 214)">
						<g id="Groupe_4024" transform="translate(-171 -1202)">
							<rect
								id="Rectangle_1748"
								width="380"
								height="709"
								fill="#fff"
								rx="10"
								transform="translate(171 1202)"
							/>
						</g>
					</g>
					<g id="Groupe_4312" transform="translate(750.658 -5136.893)">
						<g id="Groupe_4358" transform="rotate(90 -2579.276 3131.617)">
							<g id="_1" transform="translate(0 26)">
								<path id="Tracé_1" d="M0 13.7l6.844-6.852L0 0" className="cls-2" />
							</g>
						</g>
						<g id="_2" transform="rotate(90 -2503.776 3030.117)">
							<path id="Tracé_1-2" d="M0 13.7l6.844-6.852L0 0" className="cls-2" />
						</g>
						<path
							id="Groupe_3749"
							d="M212.136 463.892a7.167 7.167 0 1 0 7.234 7.167 7.2 7.2 0 0 0-7.233-7.167zm0 12.6a5.438 5.438 0 1 1 5.489-5.437 5.464 5.464 0 0 1-5.488 5.438zm0-10.163a4.727 4.727 0 1 0 4.771 4.726 4.755 4.755 0 0 0-4.771-4.722zm10.115 5.326v-8.706a.2.2 0 0 0-.354-.119 8.194 8.194 0 0 0-1.688 4.98V471a.662.662 0 0 0 .665.659h.277c-.274 2.808-.456 6.735-.456 7.194a1.034 1.034 0 0 0 .724 1.276 1.046 1.046 0 0 0 1.287-.718 1.035 1.035 0 0 0 0-.558c.002-.453-.179-4.386-.456-7.194zm-18.467-1.727a1.9 1.9 0 0 0 .929-1.63l-.279-4.467a.326.326 0 0 0-.651.036l.179 3.581a.369.369 0 0 1-.736 0l-.09-3.58a.352.352 0 0 0-.7 0l-.09 3.579a.368.368 0 0 1-.736 0l.179-3.58a.325.325 0 0 0-.646-.036l-.278 4.464a1.9 1.9 0 0 0 .928 1.63 1.075 1.075 0 0 1 .518 1.041c-.313 2.8-.527 7.384-.527 7.882a1.034 1.034 0 0 0 .724 1.276 1.046 1.046 0 0 0 1.287-.718 1.021 1.021 0 0 0 0-.558c0-.5-.213-5.078-.527-7.882a1.075 1.075 0 0 1 .515-1.033z"
							className="cls-3"
							transform="translate(-14.519 5210.453)"
						/>
						<path
							id="Tracé_1715"
							d="M209.781 528.846a23.692 23.692 0 0 0 4.554-3.639l.8.058 3.8 8.857.746-.767-.513-3.881c.729-.9 1.152-1.6.964-1.818l-.012-.013c-.156-.146-.572.048-1.132.455l-.34-2.555c.164-.2.314-.387.447-.562a3.556 3.556 0 0 0 .645-1.122.27.27 0 0 0-.034-.237l-.013-.014c-.156-.143-.553.039-1.1.428l-.135.1-.364-2.73s4.245-4.046 3.321-4.955-5.012 3.284-5.012 3.284l-2.757-.356c.034-.046.069-.09.1-.134.392-.534.578-.929.432-1.08l-.012-.012a.275.275 0 0 0-.24-.033 3.6 3.6 0 0 0-1.134.636c-.178.131-.371.279-.569.442l-2.584-.339c.417-.557.613-.969.461-1.119l-.012-.014c-.222-.184-.927.233-1.839.954l-3.924-.509-.778.736 8.958 3.758.058.79a23.651 23.651 0 0 0-3.68 4.5l-4.675-.532-.67.629 4.076 1.559a13.636 13.636 0 0 0-.762 1.239 14.086 14.086 0 0 0 1.253-.755l1.577 4.026.637-.662z"
							className="cls-3"
							transform="translate(-15.262 5192.57)"
						/>
						<path
							id="bed"
							d="M218.829 412.818v-1.349a1.377 1.377 0 0 0-1.382-1.369h-3.758c-.531 0-1.15.359-1.15 1.369v1.349zm-7.538-1.349a1.377 1.377 0 0 0-1.382-1.369h-3.526a1.377 1.377 0 0 0-1.383 1.369v1.349h6.29zm-7.537 1.349v-1.349a2.62 2.62 0 0 1 2.629-2.6h3.527a2.633 2.633 0 0 1 1.954.862 2.29 2.29 0 0 1 1.827-.862h3.758a2.618 2.618 0 0 1 2.628 2.6v1.349h1.108v-4.907c0-1.9-.312-2.2-2.223-2.2h-14.093c-1.914 0-2.223.309-2.223 2.2v4.907zm-2.48 1.234v4.831h1.593v1.578h1.251v-1.578h15.6v1.578h1.251v-1.578h1.593v-4.831z"
							className="cls-3"
							transform="translate(-14.634 5232.718)"
						/>
						<path
							id="Tracé_1714"
							d="M221.209 583.7v-2.851h-6.842v-6.943h-3.22v6.943h-6.839v2.851h6.839v6.834h3.217V583.7z"
							className="cls-3"
							transform="translate(-15.475 5168.798)"
						/>
						<g id="Groupe_4446" transform="translate(188.935 5563.485)">
							<g id="Groupe_4370">
								<path
									id="Tracé_1800"
									d="M1664.863 419.142h-1.758a1.329 1.329 0 0 0-.449.078l-1.9.681a.763.763 0 0 0-.488.765l.27 5.095a.705.705 0 0 0 .686.687h.539l.505 7.45a.564.564 0 0 0 .548.54h2.335a.564.564 0 0 0 .548-.54l.506-7.45h.539a.705.705 0 0 0 .686-.687l.27-5.095a.763.763 0 0 0-.488-.765l-1.9-.681a1.328 1.328 0 0 0-.449-.078z"
									className="cls-3"
									transform="translate(-1660.268 -414.669)"
								/>
								<circle
									id="Ellipse_147"
									cx="1.874"
									cy="1.874"
									r="1.874"
									className="cls-3"
									transform="translate(1.843)"
								/>
							</g>
							<g id="Groupe_4371" transform="translate(9.002)">
								<path
									id="Tracé_1801"
									d="M1972.451 419.142h-1.758a1.328 1.328 0 0 0-.449.078l-1.9.681a.763.763 0 0 0-.488.765l.27 5.095a.706.706 0 0 0 .686.687h.539l.506 7.45a.563.563 0 0 0 .548.54h2.335a.563.563 0 0 0 .548-.54l.506-7.45h.539a.705.705 0 0 0 .686-.687l.27-5.095a.763.763 0 0 0-.488-.765l-1.9-.681a1.33 1.33 0 0 0-.45-.078z"
									className="cls-3"
									transform="translate(-1967.855 -414.669)"
								/>
								<circle
									id="Ellipse_148"
									cx="1.874"
									cy="1.874"
									r="1.874"
									className="cls-3"
									transform="translate(1.843)"
								/>
							</g>
						</g>
						<g id="Groupe_4447" transform="translate(186.767 5599.722)">
							<path
								id="Tracé_1802"
								d="M17.2.381H8.981a.8.8 0 0 0-.8.8v16.537a.8.8 0 0 0 .8.8h1.844a.756.756 0 0 0 .756-.756v-3.777H14.6v3.779a.756.756 0 0 0 .756.756H17.2a.8.8 0 0 0 .8-.8V1.182a.8.8 0 0 0-.8-.801zm-5.241 10.581h-1.512V9.45h1.512zm0-3.023h-1.512V6.427h1.512zm0-3.023h-1.512V3.4h1.512zm3.779 6.046h-1.512V9.45h1.512zm0-3.023h-1.512V6.427h1.512zm0-3.023h-1.512V3.4h1.512z"
								className="cls-3"
								transform="translate(-3.109 -.381)"
							/>
							<path
								id="Tracé_1803"
								d="M25.633 2.381h-3.7v13.6h1.1v2.649a.379.379 0 0 0 .378.378h2.645a.379.379 0 0 0 .378-.378V3.182a.8.8 0 0 0-.801-.801zm-.68 10.581h-1.511V11.45h1.512zm0-3.023h-1.511V8.427h1.512zm0-3.023h-1.511V5.4h1.512z"
								className="cls-3"
								transform="translate(-6.467 -.869)"
							/>
							<path
								id="Tracé_1804"
								d="M1.47 3.182V18.63a.379.379 0 0 0 .378.378h2.645a.379.379 0 0 0 .378-.378v-2.645h1.1V2.381h-3.7a.8.8 0 0 0-.801.801zm1.481 8.268h1.512v1.512H2.951zm0-3.023h1.512v1.512H2.951zm0-3.023h1.512v1.512H2.951z"
								className="cls-3"
								transform="translate(-1.47 -.869)"
							/>
						</g>
						<g id="Groupe_4448" transform="translate(187.489 5527.893)">
							<g id="Capa_1">
								<path
									id="Tracé_1807"
									d="M-37.851 241.669h-1.034v-1.645a.7.7 0 0 0-.7-.7h-1.279a.7.7 0 0 0-.7.7v1.645h-8.547v-1.645a.7.7 0 0 0-.7-.7H-52.1a.7.7 0 0 0-.7.7v1.645h-.848a1.861 1.861 0 0 0-1.859 1.859v1.406h19.513v-1.406a1.86 1.86 0 0 0-1.857-1.859z"
									className="cls-3"
									transform="translate(55.506 -239.322)"
								/>
							</g>
							<g id="Calque_2" transform="translate(0 6.99)">
								<path
									id="Tracé_1808"
									d="M-55.506 248.994v10.837a1.86 1.86 0 0 0 1.859 1.858h15.8a1.86 1.86 0 0 0 1.858-1.858v-10.837zm6.747 9.888h-2.654l-.007-2.66h2.66zm0-4.419h-2.654l-.007-2.652h2.66zm4.486 4.419h-2.652l.007-2.66h2.645zm0-4.419h-2.652l.007-2.652h2.645zm4.416 0h-2.652v-2.652h2.652z"
									className="cls-3"
									transform="translate(55.506 -248.994)"
								/>
							</g>
						</g>
						<g id="Groupe_4699">
							<rect
								id="Rectangle_1972"
								width="278"
								height="20"
								className="cls-3"
								rx="10"
								transform="translate(221.342 5527.893)"
							/>
							<rect
								id="Rectangle_1973"
								width="278"
								height="20"
								className="cls-3"
								rx="10"
								transform="translate(221.342 5562.893)"
							/>
							<rect
								id="Rectangle_1974"
								width="278"
								height="20"
								className="cls-3"
								rx="10"
								transform="translate(221.342 5597.893)"
							/>
							<rect
								id="Rectangle_1975"
								width="278"
								height="20"
								className="cls-3"
								rx="10"
								transform="translate(221.342 5632.893)"
							/>
							<rect
								id="Rectangle_1976"
								width="278"
								height="20"
								className="cls-3"
								rx="10"
								transform="translate(221.342 5668.893)"
							/>
							<rect
								id="Rectangle_1977"
								width="278"
								height="20"
								className="cls-3"
								rx="10"
								transform="translate(221.342 5704.893)"
							/>
							<rect
								id="Rectangle_1978"
								width="278"
								height="20"
								className="cls-3"
								rx="10"
								transform="translate(221.342 5739.893)"
							/>
						</g>
					</g>
					<g
						id="Groupe_4452"
						filter="url(#Rectangle_1244)"
						transform="translate(916.997 214)"
					>
						<rect
							id="Rectangle_1244-2"
							width="340"
							height="47"
							className="cls-4"
							rx="5"
							transform="translate(20 642)"
						/>
					</g>
					<rect
						id="Rectangle_1970"
						width="340"
						height="90"
						className="cls-4"
						rx="10"
						transform="translate(937 234)"
					/>
					<rect
						id="Rectangle_1971"
						width="340"
						height="20"
						className="cls-3"
						rx="10"
						transform="translate(937 351)"
					/>
					<rect
						id="Rectangle_1979"
						width="241"
						height="20"
						className="cls-3"
						rx="10"
						transform="translate(937 678)"
					/>
					<rect
						id="Rectangle_1984"
						width="79"
						height="20"
						className="cls-3"
						rx="10"
						transform="translate(1198 678)"
					/>
					<rect
						id="Rectangle_1980"
						width="241"
						height="20"
						className="cls-3"
						rx="10"
						transform="translate(937 706)"
					/>
					<rect
						id="Rectangle_1983"
						width="79"
						height="20"
						className="cls-3"
						rx="10"
						transform="translate(1198 706)"
					/>
					<rect
						id="Rectangle_1981"
						width="241"
						height="20"
						className="cls-3"
						rx="10"
						transform="translate(937 734)"
					/>
					<rect
						id="Rectangle_1986"
						width="241"
						height="20"
						className="cls-3"
						rx="10"
						transform="translate(937 786)"
					/>
					<rect
						id="Rectangle_1982"
						width="79"
						height="20"
						className="cls-3"
						rx="10"
						transform="translate(1198 734)"
					/>
					<rect
						id="Rectangle_1985"
						width="79"
						height="20"
						className="cls-3"
						rx="10"
						transform="translate(1198 786)"
					/>
					<path
						id="Ligne_369"
						d="M0 0L340 0"
						className="cls-5"
						transform="translate(937 770.99)"
					/>
					<path
						id="Ligne_370"
						d="M0 0L340 0"
						className="cls-5"
						transform="translate(937 661.625)"
					/>
				</g>
				<rect
					id="Rectangle_2021"
					width="380"
					height="709"
					fill="url(#linear-gradient-3)"
					rx="10"
					transform="translate(815 214)"
				/>
			</g>
		</svg>
	);
}

export default SvgIconBookingAsideLoader;
