import * as React from "react";

function IconFunnel(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="2289 -2529.5 53 53" {...props}>
			<path
				fill="currentColor"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeMiterlimit={10}
				strokeWidth={3}
				d="M.5.5l18.667 24.833a6.781 6.781 0 0 1 1.333 4V50.5l10-10V29.333a6.781 6.781 0 0 1 1.333-4L50.5.5z"
				data-name="Trac\xE9 529"
				transform="translate(2290 -2528.5)"
			/>
		</svg>
	);
}

export default IconFunnel;
