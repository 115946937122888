import * as React from "react";

function IconDuration(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.8 39.6" {...props}>
			<path
				fill="currentColor"
				d="M3467.3,1416.4c-0.9,0-1.7,0.8-1.7,1.7v10.5v0v0 v0v0c0,0.2,0.1,0.3,0.1,0.5v0l0,0l0,0v0c0.1,0.2,0.2,0.3,0.3,0.4l0,0l0,0l0,0l0,0l0,0l0,0l0,0l0,0l4.4,4.1c0.7,0.6,1.8,0.6,2.4-0.1 c0.6-0.7,0.6-1.8-0.1-2.4l0,0l-3.9-3.6v-9.6C3469,1417.2,3468.2,1416.4,3467.3,1416.4z M3489,1415.6c-0.1,0-0.2,0-0.3,0 c-0.7,0-1.4,0.5-1.5,1.2l-0.8,3.9c-4.3-10-16-14.6-26-10.3c-10,4.3-14.6,16-10.3,26s16,14.6,26,10.3c0.8-0.4,1.7-0.8,2.4-1.3l0,0 c3.5-2,6.3-5.1,8-8.7c0.4-0.8,0-1.7-0.7-2c-0.8-0.4-1.7,0-2,0.7l0,0c-1.3,2.8-3.4,5.2-6,6.9c-0.2,0.2-0.8,0.5-0.8,0.5 c-2.7,1.6-5.7,2.5-8.8,2.4c-9.2-0.1-16.6-7.6-16.6-16.8c0.1-9.2,7.6-16.6,16.8-16.6c6.8,0.1,12.9,4.2,15.4,10.6l-4.1-1.4 c-0.2-0.1-0.3-0.1-0.5-0.1c-0.8,0-1.5,0.7-1.5,1.5c0,0.7,0.4,1.2,1,1.5l7.8,2.7l0.1,0l0,0l0,0l0,0l0,0l0,0l0,0c0.1,0,0.3,0,0.4,0 h0.1c0.4,0,0.9-0.3,1.1-0.6v0l0,0l0,0l0,0l0,0l0,0c0.1-0.1,0.1-0.2,0.2-0.4v0l0,0l0,0v0l0,0l0,0l0-0.1l1.7-8 C3490.3,1416.6,3489.8,1415.7,3489,1415.6z"
				transform="translate(-3448.385 -1408.697)"
			/>
		</svg>
	);
}

export default IconDuration;
