import PropTypes from "prop-types";
import React from "react";
import first from "lodash/first";
import last from "lodash/last";
import { calculateStopDuration, getDayCount } from "app/utils/utils";
import MoreDetails from "app/pages/.shared/MoreDetails.jsx";
import ItineraryDetail from "app/pages/.shared/ItineraryDetail.jsx";
import compact from "lodash/compact";
import { FormattedMessage } from "react-intl";
import AirlineLogo from "app/pages/.shared/AirlineLogo";
import ItineraryPreview from "./ItineraryPreview";
import { TRANSPORTATION_TYPES } from "app/constants";

import "./Itinerary.scss";

class Itinerary extends React.Component {
	constructor() {
		super();
		this.toogleRouteDetails = this.toogleRouteDetails.bind(this);

		this.state = { showRouteDetails: false };
	}

	toogleRouteDetails(event) {
		event.preventDefault();
		this.setState(prevState => ({
			showRouteDetails: !prevState.showRouteDetails,
		}));
	}

	render() {
		const { inbound, outbound, transportationType, disableDetails } = this.props;
		const isTrainType = transportationType === TRANSPORTATION_TYPES.TRAIN;

		const showDetailsLabel = this.state.showRouteDetails ? (
			<FormattedMessage
				id={
					isTrainType
						? "quotation.transport.route.expanded.label"
						: "quotation.flight.route.expanded.label"
				}
			/>
		) : (
			<FormattedMessage
				id={
					isTrainType
						? "quotation.transport.route.collapsed.label"
						: "quotation.flight.route.collapsed.label"
				}
			/>
		);

		const firstOutboundLeg = first(outbound.legs);
		const outboundStopCount = outbound.legs.length - 1;

		const outboundStopDurations = outbound.legs.map(leg => {
			return leg.to.stopDuration;
		});
		const outboundStopDuration = calculateStopDuration(compact(outboundStopDurations));

		const outboundData = {};
		outboundData.departure = firstOutboundLeg.from;
		outboundData.arrival = last(outbound.legs).to;
		outboundData.stopNode =
			outboundStopCount > 0 ? (
				<div className="stop-duration">
					{outboundStopCount} stop {outboundStopDuration && `(${outboundStopDuration})`}
				</div>
			) : (
				<div className="stop-duration">
					<FormattedMessage id="general.transport.direct" />
				</div>
			);

		outboundData.arrivalDaysCounter = getDayCount(
			outboundData.departure.takeOffDate,
			outboundData.arrival.landingDate
		);

		outboundData.label = "quotation.flight.outbound.title";

		const inboundData = {};

		const firstInboundLeg = first(inbound.legs);
		const inBoundStopCount = inbound.legs.length - 1;
		const inboundStopDurations = inbound.legs.map(leg => {
			return leg.to.stopDuration;
		});
		const inboundStopDuration = calculateStopDuration(compact(inboundStopDurations));

		inboundData.departure = firstInboundLeg.from;
		inboundData.arrival = last(inbound.legs).to;
		inboundData.stopNode =
			inBoundStopCount > 0 ? (
				<div className="stop-duration">
					{inBoundStopCount} stop {inboundStopDuration && `(${inboundStopDuration})`}
				</div>
			) : (
				<div className="stop-duration">
					<FormattedMessage id="general.transport.direct" />
				</div>
			);

		inboundData.arrivalDaysCounter = getDayCount(
			inboundData.departure.takeOffDate,
			inboundData.arrival.landingDate
		);

		inboundData.label = "quotation.flight.inbound.title";

		return (
			<div className="itinerary">
				{this.state.showRouteDetails ? (
					<div
						className="quotation-flights__itinerary-details-button"
						onClick={this.toogleRouteDetails}
					>
						{showDetailsLabel}
					</div>
				) : (
					false
				)}
				<div className="itinerary__item itinerary__item--outbound ">
					<ItineraryPreview
						flight={outbound}
						flightData={outboundData}
						hidePreview={this.state.showRouteDetails}
					/>
					<div className="itinerary__route-detail">
						<MoreDetails expanded={this.state.showRouteDetails}>
							<div className="quotation-flights__itinerary-details">
								<div className="itinerary__route-detail__title">
									<FormattedMessage id="quotation.flight.outbound.title" />
								</div>
								<ItineraryDetail legs={outbound.legs} isTrainType={isTrainType} />
							</div>
						</MoreDetails>
					</div>
				</div>

				<div className="itinerary__item ">
					<ItineraryPreview
						flight={inbound}
						flightData={inboundData}
						hidePreview={this.state.showRouteDetails}
					/>
					<div className="itinerary__route-detail">
						<MoreDetails expanded={this.state.showRouteDetails}>
							<div className="quotation-flights__itinerary-details">
								<div className="itinerary__route-detail__title">
									<FormattedMessage id="quotation.flight.inbound.title" />
								</div>
								<ItineraryDetail legs={inbound.legs} isTrainType={isTrainType} />
							</div>
						</MoreDetails>
					</div>
				</div>

				{inbound.airline.code === "TO" && outbound.airline.code === "TO" ? (
					<div className="itinerary__airline-note">
						<div className="airline-note">
							<div className="airline-note__logo">
								<AirlineLogo airlineCode={inbound.airline.code} />
							</div>
							<div className="airline-note__text">
								<FormattedMessage id="quotation.flights.transavia.note" />
							</div>
						</div>
					</div>
				) : (
					false
				)}

				{!disableDetails && (
					<div
						className="quotation-flights__itinerary-details-button"
						onClick={this.toogleRouteDetails}
					>
						{showDetailsLabel}
					</div>
				)}
			</div>
		);
	}
}
Itinerary.defaultProps = {
	inbound: {},
	outbound: {},
};

Itinerary.propTypes = {
	transportationType: PropTypes.string,
	inbound: PropTypes.object,
	outbound: PropTypes.object,
	disableDetails: PropTypes.bool,
};

export default Itinerary;
