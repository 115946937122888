import * as React from "react";

function SvgIconMagnifyingGlass(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.965 33.009" {...props}>
			<path
				fill="currentColor"
				d="M32.578 31.2l-10-10a12.762 12.762 0 0 0 2.93-8.274A12.874 12.874 0 0 0 12.755 0 12.765 12.765 0 0 0 0 12.928a12.765 12.765 0 0 0 12.755 12.927 13.025 13.025 0 0 0 8.446-3.1l10 10a1.049 1.049 0 0 0 1.379 0 .966.966 0 0 0-.002-1.555zm-19.823-7.455A10.778 10.778 0 0 1 2.068 12.886 10.778 10.778 0 0 1 12.755 2.027a10.778 10.778 0 0 1 10.687 10.859 10.778 10.778 0 0 1-10.687 10.859z"
			/>
		</svg>
	);
}

export default SvgIconMagnifyingGlass;
