import * as React from "react";

function IconInfoBubble(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.263 14.597" {...props}>
			<path
				fill="currentColor"
				d="M5.132 1.71a.428.428 0 000 .855 2.569 2.569 0 012.566 2.566.428.428 0 00.855 0A3.425 3.425 0 005.132 1.71z"
			/>
			<path
				fill="currentColor"
				d="M4.029.114A5.129 5.129 0 001.29 8.536a3.332 3.332 0 01.843 2.183v.855a1.285 1.285 0 00.886 1.22 2.133 2.133 0 004.214 0 1.285 1.285 0 00.886-1.22v-.855a3.376 3.376 0 01.871-2.211A5.133 5.133 0 004.029.114zm1.1 13.627a1.309 1.309 0 01-1.21-.887h2.419a1.309 1.309 0 01-1.207.887zm2.138-2.167a.428.428 0 01-.428.428H3.421a.428.428 0 01-.431-.428v-.428h4.28zm1.08-3.631a4.288 4.288 0 00-1.057 2.345H2.97a4.226 4.226 0 00-1.036-2.324A4.277 4.277 0 118.35 7.94z"
			/>
		</svg>
	);
}

export default IconInfoBubble;
