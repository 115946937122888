import * as React from "react";

function SvgIconMagnifyingGlassBold(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.059 21" {...props}>
			<path
				fill="currentColor"
				stroke="currentColor"
				d="M19.851 18.513l-.149-.149-4.61-4.61a8.73 8.73 0 0 0 1.71-5.2A8.57 8.57 0 0 0 8.4 0 8.377 8.377 0 0 0 0 8.4v.074A8.679 8.679 0 0 0 2.379 14.5a8.088 8.088 0 0 0 5.948 2.528H8.4a8.457 8.457 0 0 0 5.279-1.859l4.61 4.61a1.115 1.115 0 0 0 1.338 0 .781.781 0 0 0 .373-.671.5.5 0 0 0-.149-.595zM14.8 8.476a6.394 6.394 0 1 1-12.788 0A6.41 6.41 0 0 1 8.4 2.007a6.506 6.506 0 0 1 6.4 6.469z"
				transform="translate(.5 .5)"
			/>
		</svg>
	);
}

export default SvgIconMagnifyingGlassBold;
