import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import "./listing-actionbar.scss";
import { FormattedMessage, FormattedPlural } from "react-intl";
import IconListview from "app/pages/.shared/static/icons/IconListview";
import IconCardview from "app/pages/.shared/static/icons/IconCardview";
import AtolButtonLabel from "app/pages/Booking/AtolButtonLabel";
import PartnershipWithPFSLabel from "app/pages/.shared/PartnershipWithPFSLabel/PartnershipWithPFSLabel";

class ListingActionbar extends React.PureComponent {
	render() {
		const {
			listViewDisplay,
			count,
			label,
			onCardButtonClick,
			onListButtonClick,
			hideSwitchListingViewButtons,
			enableAtol,
			displayPartnershipWithPFSMessage,
		} = this.props;

		const cardViewButtonClassName = classNames({
			"icon-switchview": true,
			"icon-switchview--active": !listViewDisplay,
		});

		const listViewButtonClassName = classNames({
			"icon-switchview": true,
			"icon-switchview--active": listViewDisplay,
		});

		const actionbarClassName = classNames({
			"listing-actionbar": true,
			"listing-actionbar--unique": !count && count !== 0,
		});

		let leftSideComponent = <div className="listing-actionbar__first-element" />;

		if (enableAtol) {
			leftSideComponent = (
				<div className="atol-protected">
					<AtolButtonLabel />
				</div>
			);
		} else if (displayPartnershipWithPFSMessage) {
			leftSideComponent = <PartnershipWithPFSLabel />;
		}

		return (
			<h1 className={actionbarClassName}>
				{leftSideComponent}
				{label ? <div className="listing-actionbar__title">{label}</div> : false}
				{count > 0 && (
					<div className="listing-actionbar__count">
						<FormattedPlural
							value={count}
							one={
								<FormattedMessage
									values={{
										value: (
											<span className="listing-actionbar__count-number">
												{count}
											</span>
										),
									}}
									id="listing.products.count.singular"
								/>
							}
							other={
								<FormattedMessage
									values={{
										value: (
											<span className="listing-actionbar__count-number">
												{count}
											</span>
										),
									}}
									id="listing.products.count.plural"
								/>
							}
						/>
					</div>
				)}
				<div className="listing-actionbar__switch-view">
					{!hideSwitchListingViewButtons && (
						<span className={cardViewButtonClassName} onClick={onCardButtonClick}>
							<IconCardview height={20} width={20} />
						</span>
					)}
					{!hideSwitchListingViewButtons && (
						<span className={listViewButtonClassName} onClick={onListButtonClick}>
							<IconListview height={20} width={20} style={{ marginLeft: "10px" }} />
						</span>
					)}
				</div>
			</h1>
		);
	}
}

ListingActionbar.propTypes = {
	listViewDisplay: PropTypes.bool,
	hideSwitchListingViewButtons: PropTypes.bool,
	count: PropTypes.number,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.bool]),
	onCardButtonClick: PropTypes.func,
	onListButtonClick: PropTypes.func,
	enableAtol: PropTypes.bool,
	displayPartnershipWithPFSMessage: PropTypes.bool,
};

export default ListingActionbar;
