import * as React from "react";

function IconFlex(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.003 26.014" {...props}>
			<path
				fill="currentColor"
				stroke="rgba(0,0,0,0)"
				strokeMiterlimit={10}
				d="M7944.537,8985.54a11.868,11.868,0,0,1-8.894-6.151,12.918,12.918,0,0,1-1.491-4,12.23,12.23,0,0,1,1.581-8.381l-.011,0-1.5.279a1.044,1.044,0,1,1-.383-2.052l4.025-.751a1.046,1.046,0,0,1,1.218.834l.747,4.012a1.044,1.044,0,0,1-2.052.384l-.293-1.567a11.093,11.093,0,0,0-1.319,3.685,9.891,9.891,0,0,0,1.294,6.522,9.717,9.717,0,0,0,7.3,5.1,12.188,12.188,0,0,0,4.481-.167,1.044,1.044,0,0,1,.55,2.014,13.225,13.225,0,0,1-3.243.372A14.293,14.293,0,0,1,7944.537,8985.54Zm9.338-4.506-.746-4.012a1.043,1.043,0,1,1,2.052-.381l.291,1.564a10.986,10.986,0,0,0,1.319-3.685,9.875,9.875,0,0,0-1.292-6.52,9.715,9.715,0,0,0-7.3-5.1,12.213,12.213,0,0,0-4.481.167,1.049,1.049,0,0,1-1.281-.733c-.007-.029-.014-.059-.018-.086a1.044,1.044,0,0,1,.749-1.2,14.124,14.124,0,0,1,5.252-.228,11.84,11.84,0,0,1,8.894,6.149,11.979,11.979,0,0,1-.09,12.374l.011,0,1.5-.28a1.045,1.045,0,0,1,.383,2.055l-4.025.749a.93.93,0,0,1-.194.018A1.044,1.044,0,0,1,7953.875,8981.034Zm-9.6-2.663a6.557,6.557,0,0,1-2.068-2.889h-1.127a.964.964,0,1,1,0-1.928h.712c0-.007,0-.014,0-.021a5.781,5.781,0,0,1-.049-.639,5.854,5.854,0,0,1,.049-.641c0-.007,0-.013,0-.02h-.712a.964.964,0,1,1,0-1.929h1.127a6.568,6.568,0,0,1,2.068-2.889,5.231,5.231,0,0,1,6.281-.241.967.967,0,0,1,.252,1.342.939.939,0,0,1-.613.4.963.963,0,0,1-.726-.148,3.292,3.292,0,0,0-1.895-.6,3.8,3.8,0,0,0-3.245,2.131h3.245a.964.964,0,1,1,0,1.929h-3.858a4.618,4.618,0,0,0,0,1.319h3.046a.964.964,0,1,1,0,1.928h-2.433a3.8,3.8,0,0,0,3.245,2.133,3.312,3.312,0,0,0,1.895-.595.964.964,0,1,1,1.087,1.592,5.231,5.231,0,0,1-6.281-.241Z"
				data-name="Union 6"
				transform="translate(-7932.486 -8960.166)"
			/>
		</svg>
	);
}

export default IconFlex;
