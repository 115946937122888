import * as React from "react";

function IconDeposit(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.329 26.029" {...props}>
			<path
				fill="currentColor"
				stroke="rgba(0,0,0,0)"
				strokeMiterlimit={10}
				d="M-8467.25,20820.852a1.757,1.757,0,0,1-1.751-1.75v-16a1.761,1.761,0,0,1,1.751-1.75h15a9.767,9.767,0,0,1,8.8-5.529,9.774,9.774,0,0,1,9.777,9.766,9.767,9.767,0,0,1-5.921,8.982v4.531a1.752,1.752,0,0,1-1.75,1.75Zm.178-1.906h25.557v-3.764a10.162,10.162,0,0,1-1.928.193,9.786,9.786,0,0,1-8.8-5.533h-14.824Zm15.425-13.336a8.2,8.2,0,0,0,8.2,8.188v.006a8.208,8.208,0,0,0,8.193-8.215,8.2,8.2,0,0,0-8.2-8.187A8.208,8.208,0,0,0-8451.647,20805.609Zm-15.425.643h13.878c0-.209-.021-.418-.021-.643a10.209,10.209,0,0,1,.282-2.33h-14.14Zm23.4.82a.766.766,0,0,1-.377-.658v-7.738h1.547v7.3l3.49,2.1-.794,1.322Z"
				transform="translate(8469.501 -20795.322)"
			/>
		</svg>
	);
}

export default IconDeposit;
