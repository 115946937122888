import * as React from "react";

function IconAwesomeUnlock(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.5 36" {...props}>
			<path
				fill="currentColor"
				d="M28.125 18H10.688v-7.249a5.063 5.063 0 1 1 10.125-.063v1.125A1.683 1.683 0 0 0 22.5 13.5h2.25a1.683 1.683 0 0 0 1.688-1.687v-1.125a10.688 10.688 0 1 0-21.375.105V18H3.375A3.376 3.376 0 0 0 0 21.375v11.25A3.376 3.376 0 0 0 3.375 36h24.75a3.376 3.376 0 0 0 3.375-3.375v-11.25A3.376 3.376 0 0 0 28.125 18z"
			/>
		</svg>
	);
}

export default IconAwesomeUnlock;
