import React from "react";
import { FormattedMessage, FormattedDate } from "react-intl";
import AirlineLogo from "app/pages/.shared/AirlineLogo";
import PropTypes from "prop-types";
import classNames from "classnames";
import "./ItineraryPreview.scss";

const ItineraryPreview = ({ flight, flightData, hidePreview }) => (
	<div
		className={classNames("itinerary-preview", {
			"itinerary-preview--hidden": hidePreview,
		})}
	>
		<div className="itinerary-preview__left">
			<div className="itinerary-preview__flight-label">
				<FormattedMessage id={flightData.label} />
			</div>
			<div className="itinerary-preview__airline-logo">
				<AirlineLogo airlineCode={flight.airline.code} />
			</div>
		</div>
		<div className="itinerary-preview__right">
			<div className="itinerary-preview__title">
				<div className="itinerary-preview__airport-name">
					{flightData.departure.airport} > {flightData.arrival.airport}
				</div>
				<div className="itinerary-preview__take-off-date">
					<FormattedDate
						timeZone="UTC"
						value={new Date(flightData.departure.takeOffDate)}
						weekday="short"
						day="2-digit"
						month="short"
					/>
				</div>
			</div>
			<div className="itinerary-preview__flight-duration-detail">
				<div className="itinerary-preview__item itinerary-preview__item--takeoff">
					<div className="itinerary-preview__take-off-time">
						<div className="take-off-time">{flightData.departure.takeOffTime}</div>
					</div>
					<div className="itinerary-preview__item-iata">
						<div className="departure-iata">{flightData.departure.iata}</div>
					</div>
				</div>
				<div className="itinerary-preview__total-duration">
					<div className="itinerary-preview__arrow-data">
						<div style={{ marginBottom: "-5px" }}>{flight.totalDuration}</div>
						<i className="icon icon--itinerary-arrow" />
						<div style={{ marginTop: "-5px" }}>{flightData.stopNode}</div>
					</div>
				</div>

				<div className="itinerary-preview__item itinerary-preview__item--landing">
					<div className="itinerary-preview__time">
						<div className="itinerary-preview__landing-time">
							<div className="landing-time">{flightData.arrival.landingTime}</div>
						</div>

						{flightData.arrivalDaysCounter > 0 ? (
							<div className="itinerary-preview__days">
								<FormattedMessage
									values={{ day: flightData.arrivalDaysCounter }}
									id="general.arrival.extra.day.counter"
								/>
							</div>
						) : (
							false
						)}
					</div>

					<div className="itinerary-preview__item-iata">
						<div className="arrival-iata">{flightData.arrival.iata}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
);

ItineraryPreview.propTypes = {
	flight: PropTypes.object,
	flightData: PropTypes.object,
	hidePreview: PropTypes.bool,
};

ItineraryPreview.defaultProps = {
	flight: {},
	flightData: {},
	hidePreview: false,
};

export default ItineraryPreview;
