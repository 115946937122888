import * as React from "react";

function IconPlane2(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.986 17.648" {...props}>
			<path
				fill="currentColor"
				d="M25.072,9.384c-1.242,1-3.105,2.33-5.222,3.761l.643,9.052-1.834,1.284-3.131-7.5c-1.9,1.216-3.789,2.4-5.457,3.406l.017.024.176,2.724L8.783,23.174l-4.3-6.145L5.96,15.994l2.5,1.1.017.024c1.518-1.224,3.275-2.6,5.066-3.964L7.57,7.645,9.4,6.361l8.285,3.7c2.079-1.527,3.964-2.818,5.332-3.646,1.036-.625,2.662-.877,3.282-.095.518.841-.276,2.283-1.218,3.042"
				data-name="Trac\xE9 1614"
				transform="translate(-4.481 -5.832)"
			/>
		</svg>
	);
}

export default IconPlane2;
