import * as React from "react";

function IconNegoTrain(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.049 17.871" {...props}>
			<g>
				<g>
					<path
						fill="currentColor"
						d="M39.525,0C35.762,0,32,.47,32,3.762V12.7a3.3,3.3,0,0,0,3.292,3.292L33.881,17.4v.47h2.1L37.86,15.99h3.546l1.881,1.881h1.881V17.4L43.757,15.99A3.3,3.3,0,0,0,47.049,12.7V3.762C47.049.47,43.682,0,39.525,0ZM35.292,14.109A1.411,1.411,0,1,1,36.7,12.7,1.409,1.409,0,0,1,35.292,14.109Zm3.292-6.584h-4.7V3.762h4.7V7.525Zm5.173,6.584A1.411,1.411,0,1,1,45.168,12.7,1.409,1.409,0,0,1,43.757,14.109Zm1.411-6.584h-4.7V3.762h4.7Z"
						transform="translate(-32)"
					/>
				</g>
			</g>
		</svg>
	);
}

export default IconNegoTrain;
