import * as React from "react";

function IconCabin(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 40" {...props}>
			<g id="Groupe_4419" transform="translate(-699.592 -869.666)">
				<path
					fill="currentColor"
					d="M731.473 897.6c-3.6 0-6-.336-15.016 1.427a.639.639 0 0 1-.681-.266L712.653 893h11.532l4.018.914a1.027 1.027 0 0 0 1.121-.883v-1.749a1.027 1.027 0 0 0-1.121-.883h-16.96l-6.2-11.439.493-7.168c.081-1.172-1.073-2.13-2.563-2.13a2.694 2.694 0 0 0-2.856 2.13l-.484 7.035a1.673 1.673 0 0 0 0 .742l8.158 24.976h8.314a.9.9 0 0 1 .984.775v1.039c0 .428-.44 1.485-.984 1.485h-7.2a.818.818 0 0 0-.893.7v.412a.818.818 0 0 0 .893.7h20.352a.817.817 0 0 0 .892-.7v-.412a.817.817 0 0 0-.892-.7h-8.043c-.544 0-.984-1.057-.984-1.485v-1.039a.9.9 0 0 1 .984-.775h8.636a1.662 1.662 0 0 0 1.384-.656l3.226-4.8s1.088-1.489-2.987-1.489z"
				/>
				<path
					fill="currentColor"
					d="M723.269 886.641c3.789 0 6.888-2.664 6.888-5.92v-4.441c0-3.256-3.1-5.92-6.888-5.92s-6.888 2.664-6.888 5.92v4.441c0 3.255 3.1 5.92 6.888 5.92zm-5.08-9.779c0-2.4 2.286-4.366 5.08-4.366 2.8 0 5.081 1.965 5.081 4.366v3.276c0 2.4-2.286 4.366-5.081 4.366-2.794 0-5.08-1.965-5.08-4.366z"
				/>
			</g>
		</svg>
	);
}

export default IconCabin;
