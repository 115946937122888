import * as React from "react";

function IconCalendar(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42.4" {...props}>
			<path
				fill="currentColor"
				d="M41.5,9.3v3h-41v-3c0-2.2,1.8-3.9,3.9-3.9h1.8V2c0-0.8,0.7-1.5,1.5-1.5h2.7c0.8,0,1.5,0.7,1.5,1.5c0,0,0,0,0,0 v3.5h18V2c0-0.8,0.7-1.5,1.5-1.5c0,0,0,0,0,0H34c0.8,0,1.5,0.7,1.5,1.5c0,0,0,0,0,0v3.5h2.2C39.8,5.4,41.5,7.2,41.5,9.3z M0.5,15.2 h41V38c0,2.2-1.8,3.9-3.9,3.9H4.4c-2.2,0-3.9-1.8-3.9-3.9V15.2z M27.8,26.7h5.6v-5.6h-5.6V26.7z M18.5,26.7h5.6v-5.6h-5.6L18.5,26.7 z M18.5,36h5.6v-5.6h-5.6L18.5,36z M9.1,21.1l0,5.6h5.6v-5.6H9.1z M9.1,30.4l0,5.6h5.6v-5.6H9.1z"
			/>
		</svg>
	);
}

export default IconCalendar;
