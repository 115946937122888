import * as React from "react";

function IconCircleClose(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" {...props}>
			<defs>
				<style>{".cls-1{fill:none;stroke:currentColor;stroke-width:4px}"}</style>
			</defs>
			<g id="Groupe_4459" transform="translate(-327 -35)">
				<g id="Groupe_4459-2" transform="translate(311.5 -1259.496)">
					<path
						id="Ligne_347"
						d="M0 20L20 0"
						className="cls-1"
						transform="translate(30.5 1309.496)"
					/>
					<path
						id="Ligne_348"
						d="M20 20L0 0"
						className="cls-1"
						transform="translate(30.5 1309.496)"
					/>
				</g>
				<g
					id="Ellipse_151"
					fill="none"
					stroke="currentColor"
					strokeWidth={2}
					transform="translate(327 35)"
				>
					<circle cx={25} cy={25} r={25} stroke="none" />
					<circle cx={25} cy={25} r={24} />
				</g>
			</g>
		</svg>
	);
}

export default IconCircleClose;
