import * as React from "react";

function IconList(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.899 30.786" {...props}>
			<defs>
				<style>{".cls-1{fill:currentColor}"}</style>
			</defs>
			<g id="Groupe_4465" transform="translate(0 -7.434)">
				<path
					id="Trac\xE9_1725"
					d="M43.31 49.975H15.961a2.041 2.041 0 1 1 0-4.082h27.348a2.041 2.041 0 0 1 0 4.082z"
					className="cls-1"
					transform="translate(-4.451 -12.297)"
				/>
				<path
					id="Trac\xE9_1726"
					d="M43.31 31.144H15.961a2.041 2.041 0 1 1 0-4.082h27.348a2.041 2.041 0 0 1 0 4.082z"
					className="cls-1"
					transform="translate(-4.451 -6.276)"
				/>
				<path
					id="Trac\xE9_1727"
					d="M43.31 12.313H15.961a2.041 2.041 0 1 1 0-4.082h27.348a2.041 2.041 0 0 1 0 4.082z"
					className="cls-1"
					transform="translate(-4.451 -.255)"
				/>
				<circle
					id="Ellipse_129"
					cx={2.741}
					cy={2.741}
					r={2.741}
					className="cls-1"
					transform="translate(0 7.434)"
				/>
				<circle
					id="Ellipse_130"
					cx={2.741}
					cy={2.741}
					r={2.741}
					className="cls-1"
					transform="translate(0 20.086)"
				/>
				<circle
					id="Ellipse_131"
					cx={2.741}
					cy={2.741}
					r={2.741}
					className="cls-1"
					transform="translate(0 32.738)"
				/>
			</g>
		</svg>
	);
}

export default IconList;
