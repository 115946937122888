import * as React from "react";

function IconBagageAMain(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.62 22.339" {...props}>
			<defs>
				<style>{".cls-1{fill:currentColor}"}</style>
			</defs>
			<g id="Groupe_976" data-name="Groupe 976" transform="translate(.005 -.003)">
				<g id="Groupe_976-2" data-name="Groupe 976" transform="translate(-.006 .003)">
					<path
						id="Trac\xE9_952"
						d="M21.815 284.6h-4.3v.831a2.1 2.1 0 0 1-2.052 2.136h-.87a2.1 2.1 0 0 1-2.052-2.136v-.831H8.285a1.452 1.452 0 0 1-.687-.167l-.051 1.53a2.276 2.276 0 0 0 2.294 2.365h11.631a2.45 2.45 0 0 0 2.374-2.519v-1.879a4.614 4.614 0 0 0-.385-1.98l.033.784a1.838 1.838 0 0 1-.461 1.316 1.621 1.621 0 0 1-1.218.55z"
						className="cls-1"
						data-name="Trac\xE9 952"
						transform="translate(-7.226 -265.987)"
					/>
					<path
						id="Trac\xE9_953"
						d="M12.937 6.827a2.5 2.5 0 0 1-.945-1.269 13.22 13.22 0 0 0-3.378-4.446c-2.933-2.722-4.682.143-6.086 2.446a11.134 11.134 0 0 1-1.509 2.128c-1.788 1.692-.78 6.1-.362 7.626-.1.536-.3 1.563-.5 2.483-.194.882.165 1.575.864 1.575h4.711v1.644a1.845 1.845 0 0 0 1.58 2.026h.869a1.845 1.845 0 0 0 1.58-2.026V17.37h4.767c.7 0 1.239-.743 1.209-1.661l-.065-1.927.05.021a6.619 6.619 0 0 0-2.785-6.976zm-4 12.633h-2.4v-1.506h2.4zm6.327-6.792l-14.05.261C.721 11.095.1 7.567 1.384 6.353a11.58 11.58 0 0 0 1.632-2.281C4.448 1.724 5.8-.493 8.254 1.783A12.391 12.391 0 0 1 11.464 6a3.173 3.173 0 0 0 1.213 1.579 5.854 5.854 0 0 1 2.589 5.089z"
						className="cls-1"
						data-name="Trac\xE9 953"
						transform="translate(.006 -.003)"
					/>
				</g>
			</g>
		</svg>
	);
}

export default IconBagageAMain;
