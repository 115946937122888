import * as React from "react";

function IconTripadvisorShort(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.078 11.606" {...props}>
			<path
				d="M20.528 11.313L22.006 9.7h-3.277a10.238 10.238 0 0 0-11.517 0H3.928l1.478 1.608a4.516 4.516 0 1 0 6.112 6.65l1.447 1.575 1.447-1.575a4.518 4.518 0 1 0 6.115-6.651zM8.45 17.707a3.056 3.056 0 1 1 3.056-3.056 3.056 3.056 0 0 1-3.056 3.056zm4.517-3.145a4.986 4.986 0 0 0-3.393-4.475 8.82 8.82 0 0 1 6.784 0 4.988 4.988 0 0 0-3.391 4.475zm4.516 3.145a3.056 3.056 0 1 1 3.056-3.056 3.056 3.056 0 0 1-3.056 3.056zm0-4.659a1.6 1.6 0 1 0 1.6 1.6 1.6 1.6 0 0 0-1.6-1.6zm-7.431 1.6a1.6 1.6 0 1 1-1.6-1.6 1.6 1.6 0 0 1 1.6 1.603z"
				transform="translate(-3.928 -7.932)"
			/>
		</svg>
	);
}

export default IconTripadvisorShort;
