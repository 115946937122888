import React, { useRef } from "react";
import PropTypes from "prop-types";
import useIntersectionObserver, {
	INTERSECTION_STATUS,
} from "app/pages/.shared/useIntersectionObserver";

const LazyLoad = ({ children, topOffset, once }) => {
	const ref = useRef();

	const intersectionStatus = useIntersectionObserver(ref.current, once, {
		rootMargin: `0px 0px ${topOffset}px 0px`,
	});

	return <div ref={ref}>{intersectionStatus === INTERSECTION_STATUS.ENTERING && children}</div>;
};

LazyLoad.propTypes = {
	topOffset: PropTypes.number,
	once: PropTypes.bool,
};

export default React.memo(LazyLoad);
