import * as React from "react";

function IconAccount(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26.002 26.002" {...props}>
			<path
				fill="currentColor"
				d="M80.741-19.115a13 13 0 0 0-13 13 13 13 0 0 0 13 13 13 13 0 0 0 13-13 13 13 0 0 0-13-13zm0 5.09c2.312 0 4.186 1.942 4.186 5.026s-2.213 6.142-4.186 6.142S76.555-5.916 76.555-9s1.874-5.025 4.186-5.025zm0 18.537a10.316 10.316 0 0 1-8.011-3.3A9.2 9.2 0 0 1 74.367.169a3.95 3.95 0 0 1 4.707.873l1.656 1.821 1.57-1.745a3.932 3.932 0 0 1 4.615-.942 15.911 15.911 0 0 1 1.815 1.063 10.316 10.316 0 0 1-7.989 3.273z"
				transform="translate(-67.74 19.115)"
			/>
		</svg>
	);
}

export default IconAccount;
