import * as React from "react";

function IconPictoChevron(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.1 9.4" {...props}>
			<path fill="currentColor" d="M1.4 9.4L0 8l3.3-3.3L0 1.4 1.4 0l4.7 4.7z" />
		</svg>
	);
}

export default IconPictoChevron;
