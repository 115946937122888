import * as React from "react";

function IconNegoTransfer(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.986 18.498" {...props}>
			<path fill="currentColor" d="M21.985 5.73L14.97 0v3.088H8.797v5.284h6.172v3.088z" />
			<path
				fill="currentColor"
				d="M7.015 10.125V7.037L0 12.767l7.015 5.73v-3.088h6.172v-5.284z"
			/>
		</svg>
	);
}

export default IconNegoTransfer;
