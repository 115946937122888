import * as React from "react";

function SvgIconLock(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.394 69.191" {...props}>
			<path
				fill="currentColor"
				d="M101.327 19.2h-3.2v-4.269a14.931 14.931 0 1 0-29.862 0V19.2h-3.2A1.066 1.066 0 0 0 64 20.263v26.663a4.27 4.27 0 0 0 4.266 4.266h29.862a4.27 4.27 0 0 0 4.266-4.266V20.263a1.066 1.066 0 0 0-1.067-1.063zM86.39 41.475a1.067 1.067 0 0 1-1.06 1.184h-4.266A1.067 1.067 0 0 1 80 41.475l.673-6.05a4.219 4.219 0 0 1-1.746-3.431 4.266 4.266 0 0 1 8.532 0 4.219 4.219 0 0 1-1.746 3.431zM91.729 19.2H74.665v-4.269a8.532 8.532 0 0 1 17.064 0z"
				transform="translate(-55 6)"
			/>
		</svg>
	);
}

export default SvgIconLock;
