import * as React from "react";

function IconExternalLink(props) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			id="Calque_1"
			x={0}
			y={0}
			viewBox="0 0 14.5 14.5"
			xmlSpace="preserve"
			{...props}
		>
			<style>{".st0{fill:currentColor}"}</style>
			<path
				id="Trac\xE9_1499"
				d="M13.8,0H8.6C8.2,0,7.9,0.3,7.9,0.7c0,0.4,0.3,0.7,0.7,0.7h3.7L5.5,8.1C5.2,8.3,5.2,8.8,5.4,9 s0.7,0.3,0.9,0c0,0,0,0,0,0l6.8-6.8v3.7c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7c0,0,0,0,0,0V0.7C14.5,0.3,14.2,0,13.8,0"
				className="st0"
			/>
			<path
				id="Trac\xE9_1500"
				d="M11.2,6.6c-0.4,0-0.7,0.3-0.7,0.7v5.9H1.3V3.9h5.9c0.4,0,0.7-0.3,0.7-0.7 c0-0.4-0.3-0.7-0.7-0.7H0.7C0.3,2.6,0,2.9,0,3.3c0,0,0,0,0,0v10.5c0,0.4,0.3,0.7,0.7,0.7h10.5c0.4,0,0.7-0.3,0.7-0.7V7.2 C11.8,6.9,11.5,6.6,11.2,6.6"
				className="st0"
			/>
		</svg>
	);
}

export default IconExternalLink;
