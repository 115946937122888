import * as React from "react";

function IconNegoDinner(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.432 17.246" {...props}>
			<path
				fill="currentColor"
				d="M11.059 1.126a7.1 7.1 0 107.1 7.1 7.1 7.1 0 00-7.1-7.1zm0 12.483a5.386 5.386 0 115.386-5.386 5.392 5.392 0 01-5.386 5.386z"
			/>
			<path
				fill="currentColor"
				d="M11.059 3.544a4.682 4.682 0 104.682 4.682 4.687 4.687 0 00-4.682-4.682zM20.985 8.819V.192a.194.194 0 00-.348-.117 8.164 8.164 0 00-1.659 4.932v3.155a.655.655 0 00.655.655h.273c-.269 2.781-.447 6.671-.447 7.127a1.024 1.024 0 101.973 0c0-.452-.178-4.344-.447-7.125zM2.864 7.109a1.886 1.886 0 00.911-1.615L3.5 1.073a.32.32 0 00-.638.035l.176 3.546a.361.361 0 11-.722 0L2.228 1.11a.345.345 0 00-.69 0L1.45 4.654a.361.361 0 11-.722 0l.176-3.546a.32.32 0 00-.638-.035L0 5.494a1.886 1.886 0 00.911 1.615 1.067 1.067 0 01.508 1.03C1.11 10.918.9 15.452.9 15.946a1.176 1.176 0 00.986 1.3 1.176 1.176 0 00.986-1.3c0-.494-.209-5.029-.518-7.807a1.068 1.068 0 01.51-1.03z"
			/>
		</svg>
	);
}

export default IconNegoDinner;
